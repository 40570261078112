import moment from 'moment';
import { updateVillage } from '../actions/villageAction';
import { toast } from 'react-toastify';
import { getLoggedInUserId } from '../actions/authAction';
import { getAllFamilyMemberProfiles } from '../actions/matrimonialAction';

const date_format = 'YYYY-MM-DD';
const space_char = ' ';
const trimLeft = (str, trimChar) => {
  if (trimChar === undefined) {
    trimChar = space_char;
  }
  if (str) {
    return str.replace(new RegExp('^[' + trimChar + ']+'), '');
  } else {
    return str;
  }
};
const moveVillage = async (name, newName, postVillageUpdate, isUpdateUrl = false) => {
  console.log('details from change name');
  try {
    const data = {
      village: name,
      new_village: newName,
    };
    const result = await updateVillage(data);
    console.log('Result :', result);
    if (result?.status) toast.success('Village Updated Successfully');
    if (isUpdateUrl) postVillageUpdate(newVillage);
  } catch (error) {
    console.log('failed to update the village', error);
  }
};

const generateUserUpdateData = (graph_node_id, updatedData) => {
  const logged_in_node_id = getLoggedInUserId();
  return {
    graph_node_id: graph_node_id,
    logged_in_node_id: logged_in_node_id,
    ...updatedData,
  };
};
const FormatFullDisplayName = (name, fatherName, gender, gautra, subcaste, village, mobile) => {
  const fatherPrefix = gender === 'male' ? 'S/o' : 'D/o';

  return `${name} ${subcaste || ''} (${gautra || ''}) ${fatherPrefix} ${fatherName || ''} | ${village || ''} | ${
    mobile || ''
  }`;
};

const trimRight = (str, trimChar) => {
  if (trimChar === undefined) {
    trimChar = space_char;
  }
  if (str) {
    return str.replace(new RegExp('[' + trimChar + ']+$'), '');
  } else {
    return str;
  }
};

const trimString = (str, trimChar) => {
  let strR = trimRight(str, trimChar);
  if (str) {
    return trimLeft(strR, trimChar);
  } else {
    return str;
  }
};

const getDOBFromAge = (age) => {
  return moment().subtract(age, 'years').format(date_format);
};

const isAdminNode = (node) => {
  let roles = node?.user && node?.user?.roles ? node?.user?.roles : [];
  return roles?.includes('ROLE_ADMIN');
};

const isReviewerNode = (node) => {
  let roles = node?.user && node?.user?.roles ? node?.user?.roles : [];
  return roles?.includes('ROLE_VIEWER_ONLY');
};

const getAgeFromDOB = (dob) => {
  if (!dob) return '';
  dob = dob.toString();
  let date_format = 'YYYY-MM-DD';
  if (dob && dob.length != date_format.length) {
    if (dob == '---') {
      return 0;
    } else if (dob.indexOf('--') == 4) {
      // born:2011--
      dob = dob.substr(0, 4) + '-01-01';
    } else if (dob.lastIndexOf('-') == 7) {
      // born:2011-01-
      dob = dob.substr(0, 7) + '-01';
    }
  }

  return moment().diff(dob, 'years', false);
};

const return_prefix_suffix = (name) => {
  let reutrn_name_info = {
    pruned_name: name,
    prefix: '',
    suffix: '',
    invalid_name: false,
    messages: [],
  };

  let unwanted_suffixes = ['जी', 'देवी', 'बाई', 'बाइ', 'कुमार', 'कुमारी'];
  unwanted_suffixes.forEach(function (unwanted_suffix) {
    if (name.endsWith(unwanted_suffix) && na) {
      // TODO: Ask him to remove
      let pruned_name = name.replace(unwanted_suffix, '');
      if (pruned_name.length >= 2) {
        invalid_name = true;
        name = pruned_name;
      }
    }
  });
  // if it contains non-hindi characters
  // Don't accept
};

const isEnglishLetterPresent = (str) => {
  return /[a-z]/i.test(str);
};

const containsSuffix = (name) => {
  // separately check for "जी"
  let unwanted_suffixes = ['देवी', 'बाई', 'बाइ', 'कुमार', 'कुमारी', 'पालीवाल'];
  let array_length = unwanted_suffixes.length;
  for (let i = 0; i < array_length; i++) {
    if (name.endsWith(unwanted_suffixes[i])) {
      let pruned_name = name.replace(unwanted_suffixes[i], '');
      if (pruned_name.length > 2) {
        return true;
      }
    }
  }
  return false;
};

const remove_suffixes = (name) => {
  let suffixes = [
    'कुमार',
    'राम',
    'लाल',
    'चद',
    'चंद',
    'चँद',
    'चन्द',
    'चंद्र',
    'चन्द्र',
    'चँद्र',
    'दास',
    'दान',
    'राज',
    'मल',
    'पाल',
    'सुख',
    'मल',
    'रतन',
    'स्वरूप',
    'श्याम',
    'शंकर',
    'नारायण',
    'दत्त',
    'प्रसाद',
    'नंद',
    'किशन',
    'कृष्ण',
    'बिशन',
    'विशन',
    'किशोर',
    'प्रकाश',
    'पृकाश',
  ];
  let suffix_found = false;
  let pruned_name = name;
  if (name?.endsWith('जी')) {
    name = name.replace('/जी$/', '');
  }
  suffixes.forEach(function (suffix) {
    if (!suffix_found && name?.endsWith(suffix)) {
      suffix_found = true;
      pruned_name = name.replace(suffix, '');
    }
    if (pruned_name?.length <= 2) {
      suffix_found = false;
      pruned_name = name;
    }
  });
  return pruned_name;
};

const koot_falan = (name) => {
  //name = name.trim();
  if (name) {
    name = remove_suffixes(name);
    name = name?.replace(/जी$/g, '');

    name = name.replace(/न्/g, '');
    name = name.replace(/म्/g, '');
    name = name.replace(/ँ/g, '');
    name = name.replace(/ृ/g, '');
    name = name.replace(/[ािीुूेैोौं़्ः ़]/g, ''); // Also includes nukta ( ़)

    name = name.replace(/ख़/g, 'ख');
    name = name.replace(/ज़/g, 'ज');
    name = name.replace(/फ़/g, 'फ');
    name = name.replace(/ड़/g, 'ड');

    name = name.replace(/क्ष/g, 'छ');
    name = name.replace(/त्र/g, 'त');
    name = name.replace(/ज्ञ/g, 'ग');

    name = name.replace(/ई/g, 'इ');
    name = name.replace(/ऊ/g, 'उ');

    name = name.replace(/[षश]/g, 'स');
    name = name.replace(/ख/g, 'क');
    name = name.replace(/घ/g, 'ग');
    name = name.replace(/च/g, 'स');
    name = name.replace(/छ/g, 'स');
    name = name.replace(/झ/g, 'ज');
    name = name.replace(/ठ/g, 'ट');
    name = name.replace(/ढ/g, 'ड');
    // name = name.replace(/थ/g, "त");
    name = name.replace(/ध/g, 'द');
    name = name.replace(/फ/g, 'प');
    name = name.replace(/भ/g, 'ब');
    name = name.replace(/ण/g, 'न');
  }

  // name = name.replace(/(.)\1{1,}/g,"$1"); // Removes consecutive repeated  character

  return name;
};

const makeid = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
const getSamajName = () => {
  if (typeof window === 'undefined') return '';
  const hostname = window.location.hostname;
  console.log('hostname', hostname);
  if (hostname.includes('meghwalweb.merasamaj.app')) {
    return 'मेघवाल समाज';
  } else if (hostname.includes('paliwalweb.merasamaj.app')) {
    return 'पालीवाल ब्राह्मण समाज';
  }

  return 'आपके समाज '; // Default case if no match is found
};

const searchResult = (options, inputValue) => {
  return new Promise((resolve) => {
    let start = Date.now();
    let inputKootFalan = koot_falan(inputValue.trim());
    // const res = fuse.search(inputValue).map((result) => result.item);
    const res = options.filter((item) => {
      let matched = false;
      let score = -1;
      if (item.label == inputValue) {
        score = 5;
        matched = true;
      } else if (item.label.startsWith(inputValue)) {
        score = 4;
        matched = true;
      } else if (item.label.startsWith(inputKootFalan)) {
        score = 3;
        matched = true;
      } else if (item.kootfalan.startsWith(inputKootFalan)) {
        score = 2;
        matched = true;
      }
      item.score = score;
      return matched;
    });
    res.sort((a, b) => b.score - a.score);

    let timeTaken = Date.now() - start;
    // console.log(inputValue);
    console.log('time taken ', timeTaken);
    resolve(res.slice(0, 10));
  });
};

const getPhoneNumberFromProfile = (profile, setContactNumbers, showFaimlyContactNumbers = false) => {
  const phoneNumbers = new Set();

  // Extract phone numbers from the main profile
  if (profile?.metadata?.profile_data) {
    const { registered_mobile, primary_mobile, secondary_mobile, whatsapp_mobile } = profile.metadata.profile_data;

    if (registered_mobile) phoneNumbers.add(registered_mobile);
    if (whatsapp_mobile?.number) phoneNumbers.add(whatsapp_mobile.number);
    if (primary_mobile?.number) phoneNumbers.add(primary_mobile.number);
    if (secondary_mobile?.number) phoneNumbers.add(secondary_mobile.number);
  }

  if (Array.isArray(profile?.independent_profile_data?.contact_number)) {
    profile.independent_profile_data.contact_number.forEach((num) => phoneNumbers.add(num));
  }

  // Convert Set to Array and return immediately
  const initialPhoneNumbers = Array.from(phoneNumbers);
  console.log('initial phone number', initialPhoneNumbers);
  setContactNumbers(initialPhoneNumbers);

  // Fetch family member numbers asynchronously
  if (showFaimlyContactNumbers) {
    getAllFamilyMemberProfiles(profile?._id)
      .then((response) => {
        (response?.profiles || []).forEach((familyMember) => {
          if (familyMember?.contact_number?.[0]) {
            phoneNumbers.add(familyMember.contact_number[0]);
          }
        });

        // Update state with combined phone numbers
        setContactNumbers(Array.from(phoneNumbers));
      })
      .catch((error) => {
        console.error('Error fetching family member profiles:', error);
      });
  }
};

export {
  trimString,
  getDOBFromAge,
  generateUserUpdateData,
  getAgeFromDOB,
  containsSuffix,
  isEnglishLetterPresent,
  koot_falan,
  isAdminNode,
  isReviewerNode,
  makeid,
  searchResult,
  moveVillage,
  getSamajName,
  FormatFullDisplayName,
  getPhoneNumberFromProfile,
};
